import Glide, {
  Autoplay,
  Breakpoints,
  Controls,
  Swipe,
} from "@glidejs/glide/dist/glide.modular.esm";

jQuery(document).ready(function () {
  // jQuery(window).scroll(function () {
  //     if (jQuery(this).scrollTop() > 400) {
  //         jQuery("#backtotop")
  //             .removeClass("opacity-0 invisible")
  //             .addClass("opacity-100 visible");
  //     } else {
  //         jQuery("#backtotop")
  //             .removeClass("opacity-100 visible")
  //             .addClass("opacity-0 invisible");
  //     }
  // });

  // jQuery("#backtotop").click(function () {
  //     jQuery("html, body").animate(
  //         {
  //             scrollTop: "0px",
  //         },
  //         500
  //     );
  // });

  //LIGHTGALLERY WORDPRESS
  jQuery(".wp-block-gallery,.lightgallery")
    .lightGallery({
      download: false,
      selector: "a",
    })
    .on("onBeforeSlide.lg", function () {
      jQuery(".lg-backdrop").css("opacity", "0.8");
      jQuery(".lg-sub-html").css("opacity", "0");
    });

  //HEADER
  jQuery("#menu-burger").click(function () {
    jQuery(this).toggleClass("active"); // Toggle 'active' class on click
    jQuery("#mobile-menu").toggleClass("open");
  });
  //MENU SUB-MENU
  jQuery("#mobile-menu .menu-item.menu-item-has-children").click(function () {
    if (window.matchMedia(`(max-width: 1280px)`).matches) {
      jQuery(this).toggleClass("open");
    }
  });

  //MODAL
  // jQuery('.modal--event').click(function (e) {
  //     e.preventDefault();
  //     var content = jQuery(this).find('.modal--content').html();
  //     openModal(content);
  // });
  // jQuery('.modal--bg, #modal .close').click(function () {
  //     closeModal();
  // });
  // function openModal(content) {
  //     jQuery('#modal').find('.container').append(content);
  //     jQuery('.modal--container').addClass('show');
  //     jQuery('html, body').addClass('overflow-hidden');
  // }
  // function closeModal() {
  //     jQuery('.modal--container').removeClass('show');
  //     jQuery('html, body').removeClass('overflow-hidden');
  //     setTimeout(function () {
  //         jQuery('#modal').find('.container').empty();
  //     }, 300);
  // }

  //ACCORDIONS
});

jQuery(document).ready(function () {
  // Other functionalities...

  // Accordion functionality
  jQuery(".accordion .accordion--label").click(function () {
    jQuery(this).toggleClass("active");
    jQuery(this).parent().find(".accordion--content").stop().slideToggle(400);
  });

  // Glide.js slider functionality
  function GlideAutoHeight(Glide, Components, Events) {
    const Component = {
      mount() {
        if (!Glide.settings.autoHeight) return;
        Components.Html.track.style.transition = "height 200ms ease-in-out";
        this.updateTrackHeight();
      },
      updateTrackHeight() {
        if (!Glide.settings.autoHeight) return;
        const activeSlide = Components.Html.slides[Glide.index];
        if (activeSlide) {
          Components.Html.track.style.height = `${activeSlide.offsetHeight}px`;
        }
      },
    };
    Events.on("run", () => Component.updateTrackHeight());
    Events.on("resize", () => Component.updateTrackHeight());
    return Component;
  }

  jQuery(".glide-actus").each(function () {
    const glideElement = jQuery(this);
    const slideCount = glideElement.find(".glide__slide").length;

    if (slideCount > 2) {
      const options = {
        type: "carousel",
        hoverpause: true,
        perView: Math.min(slideCount, 3), // Limit perView to the number of slides
        gap: 10, // Consistent gap between slides
        breakpoints: {
          1024: {
            perView: Math.min(slideCount, 2), // Adjust for smaller screens
            gap: 10, // Ensure gap is consistent
          },
          640: {
            perView: 1, // Single slide on smaller screens
            gap: 0, // Remove gap for single slides
            drag: true, // Enable dragging on smaller screens
          },
        },
      };

      new Glide(glideElement[0], options).mount({
        Autoplay,
        Breakpoints,
        Controls,
        Swipe,
        GlideAutoHeight,
      });
    } else {
      // Handle fewer slides manually (e.g., static layout or custom logic)
      glideElement.find(".glide__slides").addClass("static-slides");
    }
  });

});

jQuery(document).on('touchmove', function (e) {
  if (jQuery('.glide-actus')[0].contains(e.target)) {
    e.preventDefault(); // Prevent vertical scrolling when touching the swiper
  }
});
